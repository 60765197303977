<template>
    <div>
        <v-row justify="center" align="center" style="height: 400px">
            <v-col :cols="6">
                <h1> Killgissningar.se </h1>
                <v-row justify="center" class="mt-10">
                    <v-text-field
                        v-model="search_question" placeholder="Vad har du för fråga?"
                        v-on:keyup.enter="submit"/>
                    <v-btn
                        class="mt-3"
                        :loading=loading v-on:click="submit"
                        :disabled="!search_question"
                    >
                        Sök
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="answer" justify="center" align="center" class="mt-8">
            <v-col :cols="10" md="8" lg="6">
                <div
                    class="answer-box elevation-3"
                    style="
                padding: 30px;
                border-radius: 16px;
                background-color: #f9fafb;
                text-align: center;
                max-width: 600px;
                margin: 0 auto;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            "
                >
                    <p style="font-size: 20px; font-weight: 500; color: #212121; line-height: 1.6;">
                        "{{ answer }}"
                    </p>
                </div>
            </v-col>
        </v-row>


    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "Home",
    mixins: [api_mixin],
    data: function () {
        return {
            search_question: "",
            answer: "",
            loading: false,
            dialog: []
        }
    },
    methods: {
        async submit() {
            this.loading = true

            let response = await this.api_post(
                {
                    resource: `answer`,
                    data: {
                        "question": this.search_question,
                        "dialog": this.dialog
                    },
                    auth: false
                })
            console.log("response", response)
            if (response._status === "OK") {
                this.answer = response.answer
                this.dialog.push({
                    "role": "user",
                    "content": this.search_question
                })
                this.dialog.push({
                    "role": "assistant",
                    "content": this.answer
                })
            } else {
                this.answer = "Error"
            }
            this.loading = false


        }

    }
}
</script>

<style scoped>

</style>
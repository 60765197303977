<template>
    <div id="app">
        <template v-if="loaded">
            <v-app>
                <TopBar v-if="current_user"></TopBar>
                <v-main>
                    <v-container fluid>
                        <spacing>
                            <v-row>
                                <v-col>
                                    <router-view/>
                                </v-col>
                            </v-row>
                        </spacing>
                    </v-container>
                </v-main>
            </v-app>
        </template>
        <loading v-else>
        </loading>
        <IndeDialog/>
        <DeleteDialog/>
    </div>
</template>

<script>
import TopBar from "@/shared/components/layout/TopBar";
import Loading from "@/shared/components/loading";
import {token_mixin} from "@/shared/mixins/token";
import {mapActions, mapGetters} from "vuex";
import IndeDialog from "@/shared/components/dialogs/inde_dialog.vue";
import DeleteDialog from "@/shared/components/dialogs/delete_dialog.vue";
import Spacing from "@/shared/components/helper/Spacing.vue";

export default {
    name: 'App',
    mixins: [token_mixin],
    components: {
        Spacing,
        DeleteDialog,
        IndeDialog,
        Loading,
        TopBar
    },
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        ...mapGetters({current_user: "auth/current_user"})
    },
    methods: {
        ...mapActions({logout: "auth/logout", load_user: "auth/load_user", refresh_token: "auth/refresh_token"})
    },
    async created() {
        let load_user = true
        if (!this.check_token_valid()) {
            // token is not valid
            if (this.check_refresh_token_valid()) {
                load_user = await this.refresh_token()
            } else {
                // neither token or refresh token is valid
                load_user = false
            }
        } else {
            load_user = true
        }

        if (load_user) {
            await this.load_user()
        }
        this.loaded = true
    }
}
</script>

<style lang="scss">
@import "src/plugins/colors";

.free {
    background: $free;
}

.selected {
    background: $selected;
}

.booked {
    background: $booked;
}

</style>
